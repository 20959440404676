.main-graph {
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    padding: 50px;
  }
  
  input[type="number"] {
    font-size: 16px;
  }
  /* #credit-score-gauge text {
    display: none;
  } */